"use client";

import Image from "next/image";
import {
  MouseParallaxContainer,
  MouseParallaxChild,
} from "react-parallax-mouse";
import WordRotate from "@/components/magicui/word-rotate";
import { keywords } from "@/app/keywords";

export default function Parallax() {
  return (
    <div className="w-full max-w-7xl">
      <div>
        <MouseParallaxContainer
          globalFactorX={0.1}
          globalFactorY={0.1}
          className="md:bg-[url('/Lines.svg')] bg-auto bg-no-repeat bg-center flex justify-center md:p-12 lg:p-20 xl:p-24 items-center"
        >
          <MouseParallaxChild factorX={-0.2} factorY={-0.2}>
            <div className="text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-extralight lg:w-[28rem] xl:w-[40rem] 2xl:w-[46rem]">
              <h1 className="font-black text-base md:text-lg lg:text-xl xl:text-2xl">
                YOUR ONE STOP TRUCK REPAIR SHOP
              </h1>
              <div className="flex gap-x-2 md:gap-x-4 items-baseline leading-tight">
                Providing{" "}
                <WordRotate
                  className="font-black text-[#ff2740] text-lg sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl"
                  words={keywords}
                />
              </div>
              repairs.
            </div>
          </MouseParallaxChild>
          <MouseParallaxChild
            factorX={0.2}
            factorY={0.2}
            className="hidden md:block"
          >
            <Image
              src={"/X15.svg"}
              alt="Cummins X15"
              width={300}
              height={431}
            />
          </MouseParallaxChild>
        </MouseParallaxContainer>
      </div>
    </div>
  );
}
